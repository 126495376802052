
import { defineComponent, reactive, onMounted } from "vue";
import Footer from "@/components/home/Footer.vue";
// import Nav from "@/components/home/Nav.vue";

interface State {
  images: string[];
  imageRows: string[][];
}

export default defineComponent({
  components: { Footer },
  setup() {
    const state = reactive<State>({
      images: [
        "10-logo1.png",
        "10-logo2.png",
        "10-logo3.png",
        "10-logo4.png",
        "10-logo5.png",
        "10-logo6.png",
        "10-logo7.png",
        "10-logo8.png",
        "10-logo9.png",
        "10-logo10.png",
      ],
      imageRows: [],
    });

    const chunkArray = (arr: string[], chunkSize: number): string[][] => {
      const chunkedArr = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        chunkedArr.push(arr.slice(i, i + chunkSize));
      }
      return chunkedArr;
    };

    onMounted(() => {
      state.imageRows = chunkArray(state.images, 5);
    });

    return {
      state,
    };
  },
});
